import { useState, useRef, useEffect } from "react";
import WrapperMainBanner from "./Styles-MainBanner";
import Family from "../../imgs/CASAL 02.png";
import { BsPinMap } from "react-icons/bs";
import { IoIosArrowDown } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import theme from "../../theme";
import { useNavigate } from "react-router-dom";
import useFetchCities from "./useFetchCities"; // Importando o hook de cidades com cache

const MainBanner = () => {
  const isMobile = window.innerWidth <= theme.breackpoint.mobile_2.replace("px", "");
  const [city, setCity] = useState("");
  const [type, setType] = useState(false);
  const navigate = useNavigate();
  const text = isMobile ? 
  <>
    <span className="bold">o imóvel ideal para comprar em </span>
    <span className="bold">qualquer lugar do país!</span>
  </>
  :
  <>
    <span className="bold">o imóvel ideal para comprar em </span> <br />
    <span className="bold">qualquer lugar do país!</span>
  </>;
  const [selectboxValue, setSelectBoxValue] = useState(isMobile ? "Imóvel" : "Tipo do Imóvel");
  const [showOptions, setShowOptions] = useState(false);
  const options = [
    {option: "Apartamento"},
    {option: "Área Comercial"},
    {option: "Casa"},
    {option: "Casa Comercial"},
    {option: "Casa Em Condomínio"},
    {option: "Chácara"},
    {option: "Galpão/Armazém"},
    {option: "Kitnet/Studio"},
    {option: "Prédio Comercial"},
    {option: "Rancho"},
    {option: "Sala Comercial"},
    {option: "Salão Comercial"},
    {option: "Sítio/Fazenda"},
    {option: "Terreno"},
    {option: "Terreno Comercial"},
    {option: "Terreno Em Condomínio"},
  ];

  const cities = useFetchCities(); // Usando o hook de cidades com cache
  const [filteredCities, setFilteredCities] = useState([]);
  
  const handleInputChange = (e) => {
    const value = e.target.value;
    setCity(value);

    // Filtra as cidades com base no valor do input
    const filtered = cities.filter((city) =>
      city.toLowerCase().startsWith(value.toLowerCase())
    );
    setFilteredCities(filtered);
  };

  const handleSelectCity = (city) => {
    setCity(city);
    setFilteredCities([]);
  };

  const handleClick = () => {
    let data = {
      city,
      type
    };
    navigate("/buscador", { state: data });
  };

  return (
    <WrapperMainBanner>
      <div className="title">
        A maneira mais fácil de encontrar <br />
        {text}
      </div> 
      <div className="ilustration">
        <div className="family">
          <img src={Family} alt="Casal" />
        </div>
      </div>
      <div className="search_box">
        <div className="input_box">
          <BsPinMap /> 
          <div style={{ position: "relative", width: "100%" }}>
            <input 
              type="text" 
              placeholder="Cidade" 
              value={city} 
              onChange={handleInputChange}
              style={{ width: "100%", padding: "8px", boxSizing: "border-box" }}
            />
            {filteredCities.length > 0 && (
              <ul
                style={{
                  listStyle: "none",
                  margin: 0,
                  padding: 0,
                  position: "absolute",
                  width: "100%",
                  background: "#fff",
                  border: "1px solid #ccc",
                  maxHeight: "150px",
                  overflowY: "auto",
                  zIndex: 1000,
                }}
              >
                {filteredCities.map((city, index) => (
                  <li
                    key={index}
                    onClick={() => handleSelectCity(city)}
                    style={{
                      padding: "8px",
                      cursor: "pointer",
                      borderBottom: "1px solid #eee",
                    }}
                  >
                    {city}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div> 
        <div className="select-box">
          <div className="custom-selectbox">
            <span className="value" onClick={() => setShowOptions(!showOptions)}>{selectboxValue}</span>
            <div className={`options ${showOptions && "show"}`}>
              {options.map((option, index) => (
                <span
                  key={index + option.option}
                  className={`option${selectboxValue === option.option ? " selected" : ""}`}
                  onClick={() => {
                    setType(option.option);
                    setSelectBoxValue(option.option);
                    setShowOptions(false);
                  }}
                >
                  {option.option}
                </span>
              ))}
            </div>
          </div>
          <IoIosArrowDown className={showOptions && "actived"} onClick={() => setShowOptions(!showOptions)} />
        </div>
        <span onClick={handleClick} className="button">
          Buscar 
          <FaSearch className="search-mobile-icon" />
        </span>
      </div>
    </WrapperMainBanner>
  );
};

export default MainBanner;
