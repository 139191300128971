import WrapperHeader from "./Styles-Header";
import Logo from "../../imgs/COMPRE IMOVEIS.webp"
import LogoWhite from "../../imgs/LOGO BRANCO.webp"
import { Link } from "react-router-dom";
import { BsList } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import theme from "../../theme";
import styled from "styled-components";
import { AuthContext } from "../../context/AuthContext";
import PrivateRoute from "../PrivateRoute";
import { IoMdNotificationsOutline } from "react-icons/io";
import { getNumberOfUnreadNotifications } from "../../hooks/firebase";

const HeadeAdmin = styled.div `
    width: 65%;
    padding: 5px 17.5%;
    background-color: ${theme.backgroundColor.green};
    border-bottom: 1px solid snow;

    a{
        text-decoration: none;
        color: snow;
    }
`
 
const Header = (props) => {
    const isDesktop = window.innerWidth > theme.breackpoint.mobile_2.replace("px", "")
    const [showLinks, setShowLinks] = useState(isDesktop)
    const [notificationsQuantity, setNotificationsQuantity] = useState(null)
    const { currentUser, userData } = useContext(AuthContext)

    useEffect(() => {
        async function load() {
            try {
                const result = await getNumberOfUnreadNotifications(currentUser);
                setNotificationsQuantity(result);
            } catch (error) {
                console.error("Erro ao carregar notificações:", error);
            }
        }
    
        if (userData && currentUser) { 
            load(); 
    
            const interval = setInterval(() => {
                load(); 
            }, 120000); 
    
            return () => clearInterval(interval);
        }
    }, [userData, currentUser]); 
    

    const ShowLinksOnMobile = () => {
        let windowWidth = window.innerWidth
        if (windowWidth <= theme.breackpoint.mobile_2.replace("px", "")) {
            setShowLinks(!showLinks)
        }
    }
 
    
    return <>
        {userData?.role === "admin" && 
                <HeadeAdmin>
                    <Link to="/admin">Acessar Painel</Link>
                </HeadeAdmin>
            }
        <WrapperHeader switchColor={props.switchColor} isActive={showLinks}>    
            <Link to="/" className="logo">
                <img src={props.switchColor !== false ? Logo : LogoWhite} alt="logo" />
            </Link>
            <div 
                className="filler-mobile" 
                style={{display: (showLinks === true && isDesktop === false ) ? "flex" : "none"}}
                onClick={ShowLinksOnMobile}
            >
            </div>
            <div className="links" style={{display: showLinks ? "flex" : "none"}} >
                <Link to="/blog" onClick={ShowLinksOnMobile}>Blog</Link>
                <PrivateRoute>
                    <Link to="/meus-imoveis" onClick={ShowLinksOnMobile}>Meus Anúncios</Link>
                </PrivateRoute>
                <Link to="/nossos-planos" onClick={ShowLinksOnMobile}>Turbinar</Link>
                <Link to="/anunciar" onClick={ShowLinksOnMobile}>Anunciar</Link>
                {currentUser === null ? 
                    <>
                        <Link to="/entrar" onClick={ShowLinksOnMobile}>Entrar</Link>
                        <Link to="/criar-conta" onClick={ShowLinksOnMobile}>Criar Conta</Link>
                    </>
                    :
                    <Link to={"/meus-dados"}>Meu Perfil</Link>
                }
                {currentUser && 
                    <Link className="notifications" to="notificacoes">
                        {notificationsQuantity !== null &&
                            <span className="quantity">{notificationsQuantity}</span>
                        }
                        <IoMdNotificationsOutline />
                    </Link>
                }
            </div> 
            <div className="mobile-burger-button" onClick={() => setShowLinks(!showLinks)}>
                <BsList />
            </div>
            
        </WrapperHeader>
    </>
} 

export default Header