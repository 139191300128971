import { Link, useNavigate } from "react-router-dom";
import WrapperFooter from "./Footer.styles";
import CardApp from "../card_app/CardApp";
import { FaFacebookF } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { useEffect, useState } from "react";
import { getMiscDataByType } from "../../hooks/firebase";

const Footer = ({newsletter}) => {
    const redirectTo = link => window.location.href = link
    const [data, setData] = useState(null)
    const navigate = useNavigate()

    useEffect(() => {
        async function load() {
            const result = await getMiscDataByType()
            setData(result[0])
        }
        load()
    },[])

    return <WrapperFooter>
        <div className="top">
            <div className="column actions">
                <span className="title">Clique para</span>
                <span className="button" onClick={() => navigate("/anunciar")}>Anunciar</span>
            </div>
            <div className="column social">
                <span className="title">Baixe nosso app</span>
                <CardApp store="App Store" link="https://apps.apple.com/us/app/compre-im%C3%B3veis/id6737413309" />
                <CardApp store="Google Play" link="https://play.google.com/store/apps/details?id=com.auggustus6.compreapp" />
            </div>
            <div className="column social2">
                <span className="title">Redes Sociais</span>
                <div className="social-midias">
                    <a href="https://www.facebook.com/compreimoveiscom/" target="_blank">
                        <FaFacebookF />
                    </a>
                    <a href="https://www.instagram.com/compreimoveiscom/" target="_blank">
                        <FaInstagram />
                    </a>
                    <a href="https://www.youtube.com/@grupocompreimoveis" target="_blank">
                        <FaYoutube />
                    </a>
                </div>
            </div>
            <div className="column links">
                <span className="title">Links rápidos</span>
                <Link to={"/"}>Início</Link>
                <Link to={"/meus-termos-de-uso"}>Política de uso</Link>
                <Link to={"/publicidade"}>Publicidade</Link>            
            </div>
            <div className="column informative">
                {/* {newsletter === false && 
                    <>
                        <span className="title">Informativos</span>
                        <span className="description">Preencha seu e-mail e receba nossos boletins informativos.</span>
                        <input type="text" placeholder="Melhor email" className="newsletter-input" />
                    </> 
                } */}
                <span className="title">Suporte</span>
                <span className="description">
                    {data !== null && data.email} <br />
                    Whatsapp -  {data !== null && data.whatsapp} 
                </span>
            </div> 
        </div>
        <div className="bottom">
            <span className="location">{data !== null && data.address}</span>
            <span className="copy">Copyright {data !== null && data.copy} compre imóveis. Todos os direitos reservados | CNPJ  {data !== null && data.cnpj} </span>
        </div>
    </WrapperFooter>
}

export default Footer