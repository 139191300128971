import WrapperHouseAdvertisementSession from "./HouseAdvertisementSession-styles";
import { useState, useEffect } from "react";
import Carousel from "../slide_show/SlideShow";
import theme from "../../theme";
import { useQuery } from "@tanstack/react-query";
import { getPropertyList } from "../../hooks/firebase";

const HouseAdvertisementSession = (props) => {
    const [slidesPerView, setSlidesPerView] = useState(4);
    const filterName = props.filters && props.filters.length > 0 ? props.filters[0].filterName : "genericKey"
    //console.log(filterName, props.filters[0].value)

    function formatToBRL(number) {
        return (number / 100).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
        });
    }

    useEffect(() => {
        const updateSlidesPerView = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth <= theme.breackpoint.mobile_2.replace("px", "")) {
                setSlidesPerView(1);
            } else if (
                windowWidth <= theme.breackpoint.tabletsAndSmallScreens.replace("px", "")
            ) {
                setSlidesPerView(2);
            } else {
                setSlidesPerView(4);
            }
        };

        updateSlidesPerView();
        window.addEventListener("resize", updateSlidesPerView);

        return () => {
            window.removeEventListener("resize", updateSlidesPerView);
        };
    }, []);
    
    const { data: propertyData, isLoading, error,  } = useQuery({
        queryKey: ["propertyList", filterName],
        queryFn: () => getPropertyList(props.filters),
        staleTime: 15 * 60 * 1000, 
        cacheTime: 15 * 60 * 1000,
        //keepPreviousData: true, 
        refetchOnWindowFocus: false,
        retry: false
    });

    if (isLoading) return <div>Carregando imóveis...</div>;
    if (error) return <div>Erro ao carregar os imóveis.</div>;

    const formattedData =
        propertyData?.map((property) => ({
            img: property.images[0],
            uri: `/imovel/${property.id}`,
            title: `${property.street} ${property.number} ${property.city}`.slice(0, 45) + "...",
            road: property.type,
            square_meter: property.squareConstruction,
            bedrooms: property.bedroom,
            showers: property.bathroom,
            garage: property.garage,
            base_price: formatToBRL(property.price),
            alqueiro: property.alqueiro,
            square_local: property.squareMeter,
            price: "",
        })) || [];

    return (
        <WrapperHouseAdvertisementSession>
            {formattedData.length > 0 ? (
                <>
                    <span className="title">{props.title}</span>
                    <Carousel
                        items={formattedData.map((advertisement) => ({
                            img: advertisement.img,
                            uri: advertisement.uri,
                            title: advertisement.title,
                            road: advertisement.road,
                            square_meter: advertisement.square_meter,
                            bedrooms: advertisement.bedrooms,
                            showers: advertisement.showers,
                            garage: advertisement.garage,
                            base_price: advertisement.base_price,
                            price: advertisement.price,
                            alqueiro: advertisement.alqueiro,
                            square_local: advertisement.square_local,
                        }))}
                        visibleItems={slidesPerView}
                    />
                </>
            ) : (
                <div></div>
            )}
        </WrapperHouseAdvertisementSession>
    );
};

export default HouseAdvertisementSession;
