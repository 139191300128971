import { useContext } from "react";
import WrapperNavAdmin from "./NavAdmin.styles";
import { AuthContext } from "../../context/AuthContext";
import { FaSignal } from "react-icons/fa";
import { NavLink } from "react-router-dom";
import { FaMoneyBill1Wave } from "react-icons/fa6";
import { FaBarsStaggered } from "react-icons/fa6";
import { SiMicrodotblog } from "react-icons/si";
import { PiGearSixFill } from "react-icons/pi";
import { IoChatbox } from "react-icons/io5";
import { IoFlagSharp } from "react-icons/io5";
import { LuClipboardList } from "react-icons/lu";
import { FaUserLarge } from "react-icons/fa6";
import { IoMegaphone } from "react-icons/io5";


const NavAdmin = props => {
    const { userData } = useContext(AuthContext);
    const currentHost = window.location.hostname; // Obtém o domínio atual

    const websites = [
        { name: "Compre Imóveis", url: "https://www.compreimoveis.com/admin", host: "www.compreimoveis.com" },
        { name: "Alugue Imóveis", url: "https://www.alugueimoveis.com/admin", host: "www.alugueimoveis.com" }
    ];

    function handleWebsiteChange(event) {
        window.location.href = event.target.value;
    }

    return (
        <WrapperNavAdmin>
            <div className="user">
                <div className="user-img">
                    <img src={userData.photoURL} alt="" />
                </div>
                <span className="name">{userData.name}</span>
                <span className="type">Administrador</span>
                <select className="website" onChange={handleWebsiteChange} value={window.location.origin + "/admin"}>
                    {websites.map(site => (
                        <option key={site.url} value={site.url}>
                            {site.name}
                        </option>
                    ))}
                </select>
            </div>
            <div className="links">
                <NavLink to="/admin"><FaSignal /> Estatística</NavLink>
                <NavLink to="/admin-receitas"><FaMoneyBill1Wave /> Receitas</NavLink>
                <NavLink to="/admin-anuncios"><FaBarsStaggered /> Anúncios</NavLink>
                <NavLink to="/admin-lancamentos"><IoMegaphone /> Lançamentos</NavLink>
                <NavLink to="/admin-denuncias"><IoFlagSharp /> Denúncias</NavLink>
                <NavLink to="/admin-planos"><LuClipboardList /> Planos</NavLink>
                <NavLink to="/admin-blogs"><IoChatbox /> Blog</NavLink>
                <NavLink to="/admin-users"><FaUserLarge /> Usuários</NavLink>
                <NavLink to="/admin-configuracoes"><PiGearSixFill /> Configurações</NavLink>
            </div>
        </WrapperNavAdmin>
    );
};

export default NavAdmin;
