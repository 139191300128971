/*
import { useContext, useEffect, useState } from "react";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { AuthContext } from "../../context/AuthContext";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../hooks/firebase";
import MyProperty from "../my-property/MyProperty";
 
const ContainerMyProperties = () => {
    const [properties, setProperties] = useState([])
    const [filteredProperties, setFilteredProperties] = useState([]);
    const [searchContext, setSearchContext] = useState("");
    const {currentUser} = useContext(AuthContext)

    const handleKeyDown = (event) => {
        const searchValue = event.target.value.toLowerCase();
        const searchTerms = searchValue.split(' ').filter(term => term.trim() !== '');
        setSearchContext(searchValue);

        const filtered = properties.filter(property => {
            return searchTerms.every(term => 
                property.street.toLowerCase().includes(term) ||
                property.number.toLowerCase().includes(term) ||
                property.neightboard.toLowerCase().includes(term) ||
                property.city.toLowerCase().includes(term) ||
                property.state.toLowerCase().includes(term)
            );
        });

        setFilteredProperties(filtered);
    };

    useEffect(() => {
        const fetchProperties = async () => {
            const propertiesCollection = collection(db, 'properties');
            const q = query(
                propertiesCollection, 
                where("ownerId", "==", currentUser)
            );
            const propertiesSnapshot = await getDocs(q);
            
            const propertiesList = propertiesSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));
            console.log(propertiesList)
            const filteredProperties = propertiesList.filter(property => property.deletedAt === null);

            setProperties(filteredProperties)
            setFilteredProperties(filteredProperties);
        };

        fetchProperties().catch(error => {
            console.error("Erro ao buscar propriedades:", error);
        });
    }, [])

    
    return <WrapperMyData nothing={properties?.length === 0}>
        <NavBar />
        <div className="container-c">
            {properties.length > 0 ?
                <>
                    <div className="search-box">
                        <span className="title">Buscar</span>
                        <input type="text" onChange={handleKeyDown} placeholder="Ex: Condomínio Gaivota 2" />
                    </div>
                    <div className="properties">
                        {properties !== null &&  
                            filteredProperties.map((property, index) => {
                                return <MyProperty
                                    key={index+"property-element"}
                                    data={{...property, currentUser}}
                                />
                            })
                        }
                    </div>
                </>    
                :
                <div>
                    <span className="nothing">Nenhum anúncio encontrado no momento. Que tal começar a divulgar o seu imóvel e alcançar futuros moradores? Publique agora e dê o primeiro passo para concretizar essa oportunidade.</span>
                </div>
            }
        </div>
    </WrapperMyData>
}

export default ContainerMyProperties
*/

import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { getPropertiesByUser } from "../../hooks/firebase";
import MyProperty from "../my-property/MyProperty";
import WrapperMyData from "../../views/my-data/MyData.styles";
import NavBar from "../nav-bar/NavBar";
import { NotificationContext } from "../../context/NotificationContext";
import { useQuery, useQueryClient, useIsFetching } from "@tanstack/react-query";
import { FaChevronLeft } from 'react-icons/fa';
import { FaChevronRight } from 'react-icons/fa';
const PAGE_SIZE = 10; // Número de itens por página

const ContainerMyProperties = () => {  
    const [searchContext, setSearchContext] = useState(""); 
    const [page, setPage] = useState(1);
    const { currentUser } = useContext(AuthContext);
    const { addNotification } = useContext(NotificationContext);
    const queryClient = useQueryClient();
    const containerRef = useRef(null); 

    const handleKeyDown = (event) => {
        setSearchContext(event.target.value.toLowerCase());
        //setPage(1);
    };

    const useFetchQuery = (userId, page) => {
        return useQuery({
            queryKey: ['myProperties', userId, page], 
            queryFn: () => getPropertiesByUser(userId, page, PAGE_SIZE),
            keepPreviousData: true, 
            staleTime: 15 * 60 * 1000, 
            cacheTime: 15 * 60 * 1000,
        });
    };

    const { data = { properties: [], total: 0 }, isLoading, error } = useFetchQuery(currentUser, page);
    const { properties, total } = data;
    const totalPages = Math.ceil(total / PAGE_SIZE);

    const filteredProperties = properties.filter((property) =>
        searchContext.split(" ").every((term) =>
            (property.street && property.street.toLowerCase().includes(term)) ||
            (property.number && property.number.toLowerCase().includes(term)) ||
            (property.neighborhood && property.neighborhood.toLowerCase().includes(term)) ||
            (property.city && property.city.toLowerCase().includes(term)) ||
            (property.state && property.state.toLowerCase().includes(term))
        )
    );

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        }
    }, [page]); 

    return (
        <WrapperMyData nothing={properties.length === 0}>
            <NavBar />
            <div className="container-favorites" ref={containerRef}>
                {isLoading && <div>Carregando...</div>}
                {error && <div>Ocorreu um erro ao carregar os imóveis.</div>}

                {properties.length >= 1 && (
                    <>
                        <div className="search-box">
                            <span className="title">Buscar</span>
                            <input
                                type="text"
                                onChange={handleKeyDown}
                                placeholder="Ex: Condomínio Gaivota 2"
                            />
                        </div>
                        <div className="properties">
                            {filteredProperties.map((property, index) => (
                                <MyProperty
                                    key={index + "property-element"}
                                    data={{ ...property, currentUser, page: page}}
                                />
                            ))}
                        </div>

                        {/* Paginação */}
                        <div className="pagination">
                            <button
                                onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                                disabled={page === 1}
                            >
                                <FaChevronLeft />
                            </button>
                            <span>
                                {page} de {totalPages}
                            </span>
                            <button
                                onClick={() => setPage((prev) => (prev < totalPages ? prev + 1 : prev))}
                                disabled={page === totalPages}
                            >
                                <FaChevronRight />
                            </button>
                        </div>
                    </>
                )}

                {properties.length === 0 && (
                    <div>
                        <span className="nothing">Nenhum anúncio encontrado no momento. Que tal começar a divulgar o seu imóvel e alcançar futuros moradores? Publique agora e dê o primeiro passo para concretizar essa oportunidade.</span>
                    </div>
                )}
            </div>
        </WrapperMyData>
    );
};

export default ContainerMyProperties;
