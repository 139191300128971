import { useEffect, useState } from "react";
import NavAdmin from "../../components/nav-admin/NavAdmin"
import theme from "../../theme";
import WrapperAdmin from "../admin/Admin.styles"
import { styled } from 'styled-components';
import { deleteFlag, getActualPriceOfHighlight, getDocumentsByMonthAndYear, getFlagsByMonthAndYear, setFlagToApproved, setFlagToPending, updatePlan } from "../../hooks/firebase";
import { RxReload } from "react-icons/rx";
import { FaTrash } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { IoWarning } from "react-icons/io5";
import getAllPlans from './../../hooks/GetAllPlans';

const WrapperCard = styled.div`
    width: calc(47% - 10%);
    display: flex;
    align-items: center;
    background-color: white;
    margin-top: 20px;
    border-radius: 20px;
    flex-direction: column;
    padding: 5%;
    gap: 10px;

    .data{
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 10px;

        .line {
            display: flex;

            .line-item {
                width: 30%;
            }

            input {
                width: calc(70% - 30px);
                padding: 5px 15px;
                outline: none;
                border: 1px solid #111;
                border-radius: 7px;
            }
        }
    }

    .actions {
        display: flex;
        gap: 10px;

        .action {
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            padding: 17px;
            border-radius: 12px;
            color: white;
        }

        .reload {
            background-color:#FFD700;
        }

        .delete {
            background-color: #E57373;
        }

        .approve{
            background-color: ${theme.backgroundColor.green};
        }

        .disabled{
            background-color: #6D6D6D;
            cursor: initial;
        }
    }
    
`

const AdminPlans = () => {
    const [plans, setPlans] = useState({}); // Estado para armazenar os planos

    useEffect(() => {
        async function load() {
            let result = await getAllPlans();
            const plansState = result.reduce((acc, plan) => {
                acc[plan.id] = { 
                    ...plan, 
                    price: formatToCurrency(plan.price) // Formata preço ao carregar
                }; 
                return acc;
            }, {});
    
            setPlans(plansState);
        }
        load();
    }, []);
    

    const handleChange = (id, field, value) => {
        if (field === "price") {
            let numericValue = value.replace(/\D/g, "");
            let formattedValue = formatToCurrency(Number(numericValue));
    
            setPlans(prevPlans => ({
                ...prevPlans,
                [id]: {
                    ...prevPlans[id],
                    [field]: formattedValue
                }
            }));
        } else {
            setPlans(prevPlans => ({
                ...prevPlans,
                [id]: {
                    ...prevPlans[id],
                    [field]: value
                }
            }));
        }
    };
    

    const handleApprovePlan = async (planId) => {
        if (!planId) return;
        const planData = plans[planId]
        const updatedPlan = {...planData, price: currencyToNumber(planData.price), activeDays: parseInt(planData.activeDays)};

        try {
            await updatePlan(updatedPlan); 
            alert("Plano Atualizado")
        } catch (error) {
            console.error("Erro ao atualizar o plano:", error);
        }
    };

    function formatToCurrency(value) {
        return new Intl.NumberFormat('pt-BR', { 
            style: 'currency', 
            currency: 'BRL' 
        }).format(value / 100);
    }

    function currencyToNumber(value) {
        return Number(value.replace(/\D/g, "")); // Remove qualquer caractere que não seja número
    }
    

    return (
        <WrapperAdmin>
            <NavAdmin />
            <div className="main">
                <span className="header">
                    <span className="title">Planos</span>
                </span>
                <div className="body">
                    {Object.entries(plans).map(([id, plan]) => (
                        <WrapperCard key={id}>
                            <div className="data">
                                <span className="line">
                                    <span className="line-item">Nome:</span> 
                                    <input 
                                        type="text" 
                                        value={plan.title} 
                                        onChange={(e) => handleChange(id, "title", e.target.value)}
                                    />
                                </span>
                                <span className="line">
                                    <span className="line-item">Preço:</span>
                                    <input 
                                        type="text" 
                                        value={plan.price} 
                                        onChange={(e) => handleChange(id, "price", (e.target.value))}
                                    />
                                </span>
                                <span className="line">
                                    <span className="line-item">Dias Ativo:</span>
                                    <input 
                                        type="number" 
                                        value={plan.activeDays} 
                                        min="1"
                                        onChange={(e) => handleChange(id, "activeDays", e.target.value)}
                                    />
                                </span>
                            </div>
                            <div className="actions">
                                {/* <div className="action reload">
                                    <RxReload />
                                </div>   */}
                                <div className="action approve" onClick={() => handleApprovePlan(id)}>
                                    <FaCheck />
                                </div>  
                            </div>
                        </WrapperCard>
                    ))}
                </div>
            </div>
        </WrapperAdmin>
    );
};

export default AdminPlans