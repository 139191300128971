import './App.css';
import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Home from './views/home/Home';
import Header from './components/header/Header';
import Footer from './components/footer_b/Footer';
import Plans from './views/plans/Plans';
import CreateAccountPage from './views/create-account/CreateAccount';
import SearchPage from './views/search/Search';
import RegisterPropertyPage from './views/register-property/RegisterProperty';
import MyData from './views/my-data/MyData';
import MyPlan from './views/my-plan/MyPlan';
import PrivacyPolicy from './views/privacy-policy/PrivacyPolicy';
import TermsOfUse from './views/terms-of-use/TermsOfUse';
import SwitchPassword from './views/switch-password/SwitchPassword';
import MyProperties from './views/my-properties/MyProperties';
import ViewProperty from './views/view-property/ViewProperty';
import Payment from './views/payment/Payment';
import Loggin from './views/loggin/Loggin';
import Blog from './views/blog/Blog';
import { AuthContext, AuthProvider } from './context/AuthContext';
import EditPropertyPage from './views/edit-property/EditProperty';
import { useContext } from 'react';
import MyChats from './components/my-chats/MyChats';
import MyChangePassword from './components/my_change_password/MyChangePassword';
import MyInstitucional from './components/my_institutional/MyInstitutional';
import ContainerMyProperties from './components/my_properties/MyProperties';
import MyFavorites from './components/my_favorites/MyFavorites';
import ChatContainer from './components/chat_container/ChatContainer';
import BlogHome from './views/blog-home/BlogHome';
import { LoadScript } from '@react-google-maps/api';
import theme from './theme';
import HeaderMobile from './components/header_mobile/HeaderMobile';
import Notifications from './views/notifications/Notifications';
import PrivateRoute2 from './PrivateRoute2';
import LastViewed from './components/last_viewed/LastViewed';
import { NotificationProvider } from './context/NotificationContext';
import Notification from './components/notification/Notification';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import AdminHome from './views/admin/Admin';
import AdminReceitas from './views/admin-receitas/Admin';
import AdminAnuncios from './views/admin-anuncios/Admin';
import PrivateRouteADM from './PrivateRouteADM';
import AdminReports from './views/admin-flags/Admin';
import AdminPlans from './views/admin-plans/Admin';
import AdminBlogs from './views/admin-blogs/Admin';
import AdminMisc from './views/admin-misc/Admin';
import AdminUsers from './views/admin-users/Admin';
import AdminLancamentos from './views/admin-lancamentos/Admin';
const queryClient = new QueryClient();


function AppContent() {
  const location = useLocation();
  //const {currentUser, userData} = useContext(AuthContext)
  const windowWidth = window.innerWidth;
  const isMobile = (windowWidth <= theme.breackpoint.mobile_2.replace("px", ""))
  const noHeaderFooterRoutes = ['/criar-conta', '/entrar', 
                                '/admin', "/admin-receitas", 
                                "/admin-anuncios", '/admin-denuncias',
                                "/admin-planos", "/admin-blogs",
                                "/admin-configuracoes", "/admin-users",
                                "/admin-lancamentos"];
  const switchHeaderColor = ['/'].includes(location.pathname);
  const showHeaderFooter = !noHeaderFooterRoutes.includes(location.pathname);
  
  return (
    <QueryClientProvider client={queryClient}>
    <NotificationProvider>
      <AuthProvider>
            {showHeaderFooter && !isMobile && <Header switchColor={switchHeaderColor} />}
            <Notification />
            <Routes>
                <Route index element={<Home />} />
                <Route path='/imovel/:id' element={<ViewProperty />} />
                <Route path='/nossos-planos' element={<Plans />} />
                <Route path='/criar-conta' element={<CreateAccountPage />} />
                <Route path='/buscador' element={<SearchPage type="normal" />} />
                <Route path='/buscador-de-lancamento' element={<SearchPage type="lancamento" />} />
                <Route path='/anunciar' element={
                    <PrivateRoute2>
                        <RegisterPropertyPage />
                    </PrivateRoute2>} />
                <Route path='/blog' element={<BlogHome />} />
                <Route path='/blog/:slug' element={<Blog />} />
                <Route path='/pagamento/:id/:planId/:clientId' element={
                    <PrivateRoute2>
                        <Payment />
                    </PrivateRoute2>
                } />
                <Route path='/editar-anuncio/:propertyId' element={
                    <PrivateRoute2>
                        <EditPropertyPage />
                    </PrivateRoute2>
                } />
                <Route path='/entrar' element={<Loggin />} />
                <Route path="/meus-termos-de-uso" element={<TermsOfUse />} />
                <Route path="/meus-chats" element={
                    <PrivateRoute2>
                        <MyChats />
                    </PrivateRoute2>
                } />
                <Route path="/alterar-senha" element={
                        <MyChangePassword />
                } />
                <Route path="/vistos-por-ultimo" element={
                    <PrivateRoute2>
                        <LastViewed />
                    </PrivateRoute2>
                } />
                <Route path="/institucional" element={<MyInstitucional />} />
                <Route path="/meus-imoveis" element={
                    <PrivateRoute2>
                        <ContainerMyProperties />
                    </PrivateRoute2>
                } />
                <Route path="/meus-imoveis-favoritos" element={
                    <PrivateRoute2>
                        <MyFavorites />
                    </PrivateRoute2>
                } />
                <Route path="/chat/:chatid" element={
                    <PrivateRoute2>
                        <ChatContainer />
                    </PrivateRoute2>
                }/>
                <Route path='/meus-dados' element={
                    <PrivateRoute2>
                        <MyData />
                    </PrivateRoute2>
                } />
                <Route path='/notificacoes' element={
                    <PrivateRoute2>
                        <Notifications />
                    </PrivateRoute2>
                }/>
                <Route path='/admin' element={
                    <PrivateRouteADM>
                        <AdminHome />
                    </PrivateRouteADM>
                } />
                <Route path='/admin-receitas' element={
                    <PrivateRouteADM>
                        <AdminReceitas />
                    </PrivateRouteADM>
                } />
                <Route path='/admin-anuncios' element={
                    <PrivateRouteADM>
                        <AdminAnuncios />
                    </PrivateRouteADM>
                } />
                <Route path="/admin-denuncias" element={
                    <PrivateRouteADM>
                        <AdminReports />
                    </PrivateRouteADM>
                } />
                <Route path="/admin-planos" element={
                    <PrivateRouteADM>
                        <AdminPlans />
                    </PrivateRouteADM>
                } />
                <Route path="/admin-blogs" element={
                    <PrivateRouteADM>
                        <AdminBlogs />
                    </PrivateRouteADM>
                } />
                <Route path="/admin-configuracoes" element={
                    <PrivateRouteADM>
                        <AdminMisc />
                    </PrivateRouteADM>
                } />
                <Route path='/admin-users' element={
                    <PrivateRouteADM>
                        <AdminUsers />  
                    </PrivateRouteADM>
                } />
                <Route path="/admin-lancamentos" element={
                    <PrivateRouteADM>
                        <AdminLancamentos />
                    </PrivateRouteADM>
                } />
                </Routes>
            {isMobile && <HeaderMobile />}
            {showHeaderFooter && !isMobile && <Footer newsletter={false} />}
      </AuthProvider>
    </NotificationProvider>
    </QueryClientProvider>
  );
}


function App() {
    return (
        <div className="App">
            <LoadScript googleMapsApiKey='AIzaSyDVrDZ19GzGLSdUt4JEEQ1RXQK9N1IE24Y' libraries={["places"]}  >
                <BrowserRouter>
                    <AppContent />
                </BrowserRouter>
            </LoadScript>
        </div>
    );
}

export default App;
