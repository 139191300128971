import { useContext, useEffect, useRef, useState } from "react";
import { WrapperBoleto, WrapperCartaoCredito, WrapperPayment, WrapperPix} from "./Payment.styles";
import { FaPix } from "react-icons/fa6";
import { BiBarcodeReader } from "react-icons/bi";
import { BsFillCreditCard2FrontFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import PaymentCartao from "../payment_cartao/PaymentCartao";
import PaymentBoleto from "../payment_boleto/PaymentBoleto";
import { NotificationContext } from "../../context/NotificationContext";
import { activatePropertyPlan, serverURL } from "../../hooks/firebase";
import { encriptCard } from "../../hooks/encriptCard";
import { AuthContext } from './../../context/AuthContext';

const Payment = props => {
    const [selectedOption, setSelectedOption] = useState("pix");
    const { id, planId, clientId } = useParams()
    const [showPix, setShowPix] = useState(false)
    const [buttonMessage, setButtonMessage] = useState(false)
    const [pixData, setPixData] = useState(false)
    const [barcode, setBarCode] = useState(false)
    const [pdfBarcode, setPdfBarcode] = useState(false)
    const [cardData, setCardData] = useState({})
    const [address, setAddress] = useState({}) 
    const {userData} = useContext(AuthContext)
    const {addNotification} = useContext(NotificationContext)
    const navigate = useNavigate()

    useEffect(() => {
        async function isAdmin(){
            if (userData?.role === "admin") {
                await activatePropertyPlan(id)
                navigate(`/imovel/${id}`)
                return;
            }
        }
        isAdmin();
    },[userData])
    
    const handleCardData = (data) => {
        let dueDateSplited = data.dueDate.split("/") //12/2026
        let [encrypted, hasErrors] = encriptCard({
            cardName: data.cardName,
            cardNumber: data.cardNumber,
            expMonth: dueDateSplited[0],
            expYear: dueDateSplited[1],
            securityCode: data.cvv,
        })
        
        createCreditPayment({
            cardName: data.cardName, 
            encript: encrypted,
        })
    }

    const handleAddressData = (data) => {
        let address = data
        generateboleto(address)
    }
    
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    function generatePIXCode() {
        setButtonMessage(true)
        const target = serverURL+"/criarPagamentoPix"
        axios.post(target, {
            propertyId: id,
            planId,
            clientId
        }, {headers: {
            'Content-Type': 'application/json'
        }}).then(result => {
            const qrcodetext = result.data.data.qr_codes[0].text;
            const qrImage = result.data.data.qr_codes[0].links[0].href;
            setShowPix(true)
            setButtonMessage(false)
            setPixData({qrcodetext, qrImage})
        }).catch(e => {
            console.log(e)
        })
    }

    function createCreditPayment(card) {
        const target = serverURL+"/criarPagamentoCredito"
        axios.post(target, {
            propertyId: id,
            planId,
            clientId,
            installments: 1,
            ...card
        }, {headers: {
            'Content-Type': 'application/json'
        }}).then(result => {
            console.log(result)
            const message = result.data.message
            if (message === "Pagamento criado com sucesso") {
                navigate("/meus-imoveis")
                addNotification("Pagamento Realizado", "success")
            } else {
                addNotification("Pagamento Não Realizado", "error")
            }
        }).catch(e => {
            console.log(e)
            addNotification("Pagamento Não Realizado", "error")
        }) 
    }

    function generateboleto(address) {
        const dataToCreate = {
            propertyId: id,
            planId,
            clientId,
            country: "Brasil",
            region: address.city,
            region_code: address.state,
            city: address.city,
            postal_code: address.cep,
            street: address.street,
            number: address.number,
            locality: address.neightboard
        }
        const target = serverURL+"/criarPagamentoBoleto"
        axios.post(target, dataToCreate, {headers: {
            'Content-Type': 'application/json'
        }}).then(result => {
            const charges = result.data.data.charges[0]
            const boletocode= charges.payment_method.boleto.barcode;
            const boletopdf = charges.links[0].href;
            
            setBarCode(boletocode)
            setPdfBarcode(boletopdf)
        }).catch(e => {
            console.log(e)
        })
    }

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text)
    };

    const copyPIXCode = () => {
        copyToClipboard(pixData.qrcodetext)
    }

    const MethodView = () => {

        if (selectedOption === "pix") {
            return <WrapperPix>
                <span className="title-session">
                    Código Pix
                </span>
                {showPix ? (
                    <>
                        <img src={pixData.qrImage} alt="" />
                        <input name="pix-code" type="text" className="pix-code" value={pixData.qrcodetext} /> 
                        <span className="button" onClick={copyPIXCode}>Copiar Código Pix</span>
                    </>
                ) : (
                    !buttonMessage ? (
                        <span className="button" onClick={generatePIXCode}>Gerar Código</span>
                    ) : (
                        <span className="button">Gerando...</span>
                    )
                )}
                <ul>
                    <li>Copie o código Pix acima.</li>
                    <li>Acesse o app do seu banco ou internet bancking de preferência.</li>
                    <li>Escolha pagar com o Pix, cole o código e finalize o pagamento.</li>
                    <li>Seu pagamento será aprovado em alguns segundos.</li>
                </ul>
            </WrapperPix>
        }

        if (selectedOption === "boleto") {
            return <PaymentBoleto onAddressData={handleAddressData} barcode={barcode} pdfBarcode={pdfBarcode}/>
        }

        if (selectedOption === "cartao-de-credito") {
            return <PaymentCartao onCardData={handleCardData} />
        }
    }

    function pay() {

    }

    return <WrapperPayment>
        <div className="payment-header">
            <span className="title">Pagamento</span>
            <span className="info">Escolha uma forma de pagamento</span>
            <div className="body">
                <div className="radio-group">
                    <label className="custom-radio">
                        <input
                            type="radio"
                            name="option"
                            value="pix"
                            checked={selectedOption === "pix"}
                            onChange={handleOptionChange}
                        />
                        <span className="custom-radio-circle"></span>
                        <span className="name">
                            <FaPix />
                            Pix
                        </span>
                    </label>
                    <label className="custom-radio">
                        <input
                            type="radio"
                            name="option"
                            value="boleto"
                            checked={selectedOption === "boleto"}
                            onChange={handleOptionChange}
                        />
                        <span className="custom-radio-circle"></span>
                        <span className="name">
                            <BiBarcodeReader />
                            Boleto
                        </span>
                    </label>
                    <label className="custom-radio">
                        <input
                            type="radio"
                            name="option"
                            value="cartao-de-credito"
                            checked={selectedOption === "cartao-de-credito"}
                            onChange={handleOptionChange}
                        />
                        <span className="custom-radio-circle"></span>
                        <span className="name">
                            <BsFillCreditCard2FrontFill />
                            Cartão de Crédito
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div className="input-area">
            <MethodView />
        </div>
        <div className="actions">
            <span className="button next" onClick={() => navigate("/meus-imoveis")}>Ok</span>
        </div>
        {/**<span className="button pay" onClick={generateboleto}>OK</span> */}
    </WrapperPayment>
}

export default Payment