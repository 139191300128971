import { useContext, useEffect, useRef, useState } from "react";
import NavBar from "../../components/nav-bar/NavBar";
import WrapperMyData from "./MyData.styles";
import { AuthContext } from './../../context/AuthContext';
import { updateUserInfo } from "../../hooks/firebase";
import { GoLock } from "react-icons/go";
import { FiMapPin } from "react-icons/fi";
import axios from "axios";
import { NotificationContext } from "../../context/NotificationContext";


const MyData = () => {
    const {currentUser, userData, reloadUserData} = useContext(AuthContext)
    const {addNotification} = useContext(NotificationContext)
    const [name, setName] = useState("")
    const [telephone, setTelephone] = useState("")
    const [document, setDocument] = useState("")
    const [cep, setCEP] = useState("")
    const [city, setCity] = useState("")
    const [street, setStreet] = useState("")
    const [state, setState] = useState("")
    const [neightboard, setNeightboard] = useState("")
    const [number, setNumber] = useState("")
    const [creci, setCreci] = useState(false)
    const [complement, setComplement] = useState("")
    const [documentLimit, setDocumentLimit] = useState(0)
    const [isOkToSave, setIsOkToSave] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [type, setType] = useState("")
    const CEPFeedbackRef = useRef(null)
    const feedbackIsValidDocument = useRef(null)
    const feedbackIsValidName = useRef(null)
    const feedbackTelephone = useRef(null)

    useEffect(() => {
        let isMounted = true;
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        if (userData) setIsDataLoaded(true)
        setName(userData?.name)
        setTelephone(userData?.telephone)
        setDocument(userData?.document)
        setCEP(userData?.myLocation.cep)
        setCity(userData?.myLocation.city)
        setState(userData?.myLocation.state)
        setStreet(userData?.myLocation.street)
        setNeightboard(userData?.myLocation.neightboard)
        setNumber(userData?.myLocation.number)
        setComplement(userData?.myLocation.complement)
        setType(userData?.type)
        userData?.creci &&
            setCreci(userData?.creci) 

        userData?.type === "CPF"
            ? setDocumentLimit(14)
            : setDocumentLimit(18)

        
        

        return () => {
            isMounted = false;
        };
    },[userData])

    useEffect(() => {
        if (isDataLoaded) {
            setIsDataLoaded(false)
            setIsOkToSave(false)
            return;
        }

        const validateFields = async () => {
            const isValidName = validateName(name);
            const isValidTelephone = validateTelephone(telephone);
            const isValidDocument = validateDocument(document);
            const isValidCEPValidation = cep === "" ? true : await isValidCEP(cep);
            const isValidNumber = validateNumber(number)
            const isValidComplement = validateComplement(complement)
            const isValidCreci = validateCreci(creci)
            const result = isValidName && isValidTelephone && isValidDocument && isValidCEPValidation && isValidNumber && isValidComplement && isValidCreci
    
            setIsOkToSave(result);
        };
    
        validateFields();
    }, [name, telephone, document, cep, number, complement, creci]);
    
    

    function handleSetDocument(event) {
        const inputValue = (event.target.value).slice(0, documentLimit)
        const formattedValue = formatCPFOrCNPJ(inputValue); 
        setDocument(formattedValue);
        const isValid = isValidDocument(formattedValue); 
        
        if (isValid) 
        updateFeedback(feedbackIsValidDocument, "")
    }

    function formatCPFOrCNPJ(value) {
        const cleanValue = value.replace(/\D/g, '');
    
        if (cleanValue.length <= 11) {
            if (cleanValue.length <= 2) return cleanValue;
            if (cleanValue.length <= 5) return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3)}`;
            if (cleanValue.length <= 8) return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3, 6)}.${cleanValue.slice(6)}`;
            return `${cleanValue.slice(0, 3)}.${cleanValue.slice(3, 6)}.${cleanValue.slice(6, 9)}-${cleanValue.slice(9, 11)}`;
        }
    
        if (cleanValue.length <= 14) {
            if (cleanValue.length <= 2) return cleanValue;
            if (cleanValue.length <= 5) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2)}`;
            if (cleanValue.length <= 8) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5)}`;
            if (cleanValue.length <= 12) return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5, 8)}/${cleanValue.slice(8)}`;
            return `${cleanValue.slice(0, 2)}.${cleanValue.slice(2, 5)}.${cleanValue.slice(5, 8)}/${cleanValue.slice(8, 12)}-${cleanValue.slice(12, 14)}`;
        }
    
        return cleanValue;
    }

    const isValidCEP = async (cep) => {
        if (cep === undefined || cep === "") return false;
        let formattedCep = cep.replace(/\D/g, ''); // Remove todos os caracteres não numéricos
        if (formattedCep.length !== 8) return false;  // Se o CEP não tiver 8 caracteres, é inválido
    
        try {
            const result = await axios.get(`https://viacep.com.br/ws/${formattedCep}/json/`);
            const data = result.data;
    
            if (data.erro) return false;  // Se a resposta indicar erro, o CEP é inválido
            return true;  // Se o CEP for válido, retorna true
        } catch (error) {
            return false;  // Se houver erro na requisição, considera o CEP inválido
        }
    };

    async function validateCEPOnFocusBlur(e) {
        let formattedCep = (e.currentTarget.value).replace(/\D/g, '');
        let result = await isValidCEP(formattedCep)

        if (result) {
            updateFeedback(CEPFeedbackRef, "")
        } else {
            updateFeedback(CEPFeedbackRef, "Informe um CEP correto e existente!")
        }
    }

    function isValidDocument(inputDocument) {
        const doc = inputDocument || document;
        if (doc === "" || doc === null || doc === undefined) return false;
        let tempDocument = doc.replace(/\D/g, '');
    
        function isValidCPF(cpf) {
            if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) return false;
            let sum = 0;
            for (let i = 0; i < 9; i++) sum += parseInt(cpf[i]) * (10 - i);
            let firstVerifier = (sum * 10) % 11;
            if (firstVerifier === 10 || firstVerifier === 11) firstVerifier = 0;
            if (firstVerifier !== parseInt(cpf[9])) return false;
            sum = 0;
            for (let i = 0; i < 10; i++) sum += parseInt(cpf[i]) * (11 - i);
            let secondVerifier = (sum * 10) % 11;
            if (secondVerifier === 10 || secondVerifier === 11) secondVerifier = 0;
            return secondVerifier === parseInt(cpf[10]);
        }
    
        function isValidCNPJ(cnpj) {
            if (cnpj.length !== 14 || /^(\d)\1+$/.test(cnpj)) return false;
            let sum = 0;
            const firstWeights = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            for (let i = 0; i < 12; i++) sum += parseInt(cnpj[i]) * firstWeights[i];
            let firstVerifier = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            if (firstVerifier !== parseInt(cnpj[12])) return false;
            sum = 0;
            const secondWeights = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
            for (let i = 0; i < 13; i++) sum += parseInt(cnpj[i]) * secondWeights[i];
            let secondVerifier = sum % 11 < 2 ? 0 : 11 - (sum % 11);
            return secondVerifier === parseInt(cnpj[13]);
        }
    
        if (tempDocument.length === 11) {
            return isValidCPF(tempDocument);
        } else if (tempDocument.length === 14) {
            return isValidCNPJ(tempDocument);
        } else {
            return false;
        }
    }

    function handleIsValidDocument() {
        let isValid = isValidDocument()
        if (!isValid) {
            feedbackIsValidDocument.current.innerText = "O Documento informado é invalido!"
        } else {
            feedbackIsValidDocument.current.innerText = ""
        }
    }

    function handleSetTelephone (event) {
        let tempTelephone = event.currentTarget.value
        tempTelephone = tempTelephone.trim().slice(0, 15)
        let temp = formatPhoneNumber(tempTelephone)
        setTelephone(temp)

        if (tempTelephone.length >= 14) 
            feedbackTelephone.current.innerText = ""
    }

    function validateTelephoneOnFocusBlur(event) {
        let tempTelephone = event.currentTarget.value
        tempTelephone = tempTelephone.slice(0, 15)
        if (tempTelephone.length >= 14) 
            feedbackTelephone.current.innerText = ""
        else
            feedbackTelephone.current.innerText = "Informe um telefone!" 
    }

    function formatPhoneNumber(value) {
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length === 0) return '';
    
        if (cleanValue.length <= 2) {
            return `(${cleanValue}`;
        } else if (cleanValue.length <= 6) {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2)}`;
        } else if (cleanValue.length <= 10) {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2, 6)}-${cleanValue.slice(6)}`;
        } else {
            return `(${cleanValue.slice(0, 2)}) ${cleanValue.slice(2, 7)}-${cleanValue.slice(7, 11)}`;
        }
    }

    async function isValidDataToSave(data) {
        let location = data['myLocation'];
    
        // Verifica se o nome tem no mínimo 3 caracteres
        if (data['name'].trim().length < 3) return false;
    
        // Verifica se o telefone tem no mínimo 14 caracteres
        if (data['telephone'].trim().length < 14) return false;
    
        // Verifica se o CRECI tem no mínimo 4 caracteres
        if (data['creci'] && data['creci'].trim().length < 4) return false;
    
        // Verifica se o CEP não está vazio e se é válido
        if (location['cep'] === "" ? false : !await isValidCEP(location['cep'])) return false;
    
        return true;
    }

    async function save () {
        let data = {
            uid: currentUser,
            name,
            telephone,
            document,
            creci,
            myLocation: {
                cep,
                city,
                street,
                state,
                neightboard,
                number,
                complement
            }
        }
        let isValid = await isValidDataToSave(data)
        if (!isValid) {
            addNotification("Dados fornecidos estão incorretos!", "error")
            return;
        }


        updateUserInfo(data).then(() => {
            reloadUserData()
            addNotification("Perfil atualizado com sucesso!", 'success')
        })
    }

    const handleCepBlur = (event) => {
        let cep = event.currentTarget.value
        let formattedCep = cep.replace(/\D/g, '');
        if (formattedCep.length > 5) {
            formattedCep = formattedCep.slice(0, 5) + '-' + formattedCep.slice(5, 9); // Adiciona o hífen
        }
    
        formattedCep = formattedCep.slice(0, 9);
        
        setCEP(formattedCep);
        if (formattedCep.length > 8) {
            axios.get(`https://viacep.com.br/ws/${formattedCep.replace("-", "")}/json/`)
            .then(result => {
                const data = result.data;
                if (data.erro) {
                    CEPFeedbackRef.current.innerText = "CEP incorreto ou inexistente";
                    setCity("")
                    setStreet("")
                    setNeightboard("")
                    setState("")
                    return;
                }
                setCity(data.localidade);
                setStreet(data.logradouro);
                setNeightboard(data.bairro);
                setState(data.uf);
                CEPFeedbackRef.current.innerText = ""
            }).catch(e => {

            })
        } else {
            CEPFeedbackRef.current.innerText = "";
        }
    };

    const updateFeedback = (reference, feedback) => {
        reference.current.innerText = feedback
    }

    const validateDocumentOnFocusBlur = (event) => {
        let sanitizedDocument = (event.currentTarget.value).slice(0, documentLimit)
        sanitizedDocument = formatCPFOrCNPJ(sanitizedDocument);
        let isValid = isValidDocument(sanitizedDocument); 

        if (!isValid) {
            updateFeedback(feedbackIsValidDocument, "Informe um nome válido!")
        } else {
            updateFeedback(feedbackIsValidDocument, "")
        }
    }

    const validateNameOnFocusBlur = (event) => {
        let sanitizedName = (event.currentTarget.value).slice(0, 60)
        if (sanitizedName.trim().length < 3) {
            updateFeedback(feedbackIsValidName, "Informe um nome válido!")
        } else {
            updateFeedback(feedbackIsValidName, "")
        }
    }

    const validateName = value => {
        return (value !== undefined && value.trim().length > 3);
    }

    const validateTelephone = value => {
        return (value !== undefined && value.length >= 14);
    }

    const validateDocument = value => {
        return isValidDocument(value);
    }

    const validateNumber = value => {
        if (value === undefined) return false;
        return (value.trim().slice(0,8).length >= 0)
    }

    const validateComplement = value => {
        if (value === undefined) return false;
        return (value.trim().slice(0,30).length >= 0)
    }

    const validateCreci = value => {
        if (type === "CPF") return true;
        if (value === undefined || value === false) return false;
        return (value.trim().slice(0,60).length >= 5)
    }

    const handleSetName = (event) => {
        let sanitizedName = (event.currentTarget.value).slice(0, 60)
    
        if (validateName(sanitizedName)) updateFeedback(feedbackIsValidName, "")
        setName(sanitizedName)
    }

    const handleSetNumber = event => {
        let sanitizedNumber = (event.currentTarget.value).trim().slice(0, 8)
        setNumber(sanitizedNumber)
    }

    const handleSetCreci = (event) => {
        let value = (event.currentTarget.value).slice(0, 50)
        setCreci(value);
    }

    const handleSetComplement = event => {
        let sanitizedComplement = (event.currentTarget.value).trim().slice(0, 30)
        setComplement(sanitizedComplement)
    }

    return <WrapperMyData>
        <NavBar />
        <div className="container-a">
            <div className="header">
                <GoLock /> Meus dados
            </div>
            <div className="line">
                <span className="title">{type !== "CPF" ? "Razão Social" : "Nome Completo"}</span>
                <input type="text" value={name} onChange={handleSetName} onFocus={validateNameOnFocusBlur} onBlur={validateNameOnFocusBlur} />
                <span className="feedback" ref={feedbackIsValidName}></span>
            </div>
            <div className="line">
                <span className="title">Email</span>
                <input type="text" value={userData?.email} disabled />
            </div>
            <div className="line">
                <span className="title">{type !== "CPF" ? "CNPJ" : "Documento"}</span>
                <input type="text" value={document} onChange={handleSetDocument} onFocus={validateDocumentOnFocusBlur} onBlur={validateDocumentOnFocusBlur} />
                <span className="feedback" ref={feedbackIsValidDocument}></span>
            </div>
            {creci && 
                <div className="line">
                    <span className="title">Creci</span>
                    <input type="text" value={creci} onChange={handleSetCreci} />
                </div>
            }
            <div className="line">
                <span className="title">Telefone/Whatsapp</span>
                <input type="text" value={telephone} onChange={handleSetTelephone} onFocus={validateTelephoneOnFocusBlur} onBlur={validateTelephoneOnFocusBlur} />
                <span className="feedback" ref={feedbackTelephone}></span>
            </div>
            <div className="header address">
                <FiMapPin /> Meu endereço
            </div>
            <div className="line">
                <span className="title">Cep</span>
                <input type="text" value={cep} onChange={handleCepBlur} onFocus={validateCEPOnFocusBlur} onBlur={validateCEPOnFocusBlur} />
                <span className="feedback" ref={CEPFeedbackRef}></span>
            </div>
            <div className="line">
                <span className="title">Cidade</span>
                <input type="text" value={city} disabled />
            </div>
            <div className="line">
                <span className="title">Estado</span>
                <input type="text" value={state} disabled />
            </div>
            <div className="line">
                <span className="title">Rua</span>
                <input type="text" value={street} disabled />
            </div>
            <div className="line">
                <span className="title">Bairro</span>
                <input type="text" value={neightboard} disabled />
            </div>
            <div className="line">
                <span className="title">Número</span>
                <input type="text" value={number} onChange={handleSetNumber} />
            </div>
            <div className="line">
                <span className="title">Complemento</span>
                <input type="text" value={complement} onChange={handleSetComplement} />
            </div> 
            <div className="actions">
                {isOkToSave ? 
                    <span className="button" onClick={save}>
                        Salvar
                    </span>
                :
                    <span className="button deactived">
                        Salvar
                    </span>
                }
                
            </div>
        </div>
    </WrapperMyData>
}

export default MyData