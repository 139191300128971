import { useNavigate } from "react-router-dom"
import WrapperChatCard from "../chat_card/ChatCard.styles"
import { FaUserAlt } from "react-icons/fa";
import { readNotification } from "../../hooks/firebase";



export const NotificationCard = (props) => {
    const navigate = useNavigate()
    const handleOpen = async () => {
        //console.log(props)
        await readNotification(props.id)
        navigate(`/chat/${props.chatid}`)
    }

    function obterHoraMinuto(createdAt) {
        if (!createdAt) return;
    
        // Converter o timestamp para milissegundos
        const totalMilliseconds = (createdAt._seconds * 1000) + (createdAt._nanoseconds / 1_000_000);
        const date = new Date(totalMilliseconds);
    
        // Obter a data atual e calcular a diferença em milissegundos
        const now = new Date();
        const diff = now - date;
    
        // 24 horas em milissegundos
        const oneDayInMs = 24 * 60 * 60 * 1000;
    
        if (diff < oneDayInMs) {
            // Dentro de 24 horas: retornar somente o horário
            const horas = date.getHours();
            const minutos = date.getMinutes();
            return `${horas}:${minutos.toString().padStart(2, '0')}`;
        } else {
            // Mais de 24 horas: retornar no formato hh:mm - dd/mm/yy
            const horas = date.getHours();
            const minutos = date.getMinutes();
            const dia = date.getDate().toString().padStart(2, '0');
            const mes = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses começam do 0
            const ano = date.getFullYear().toString().slice(-2); // Pegar os dois últimos dígitos do ano
            return `${horas}:${minutos.toString().padStart(2, '0')} - ${dia}/${mes}/${ano}`;
        }
    }
    

    return <WrapperChatCard onClick={handleOpen}>
        {props.photoURL !== "" ?
            <img src={props?.photoURL} alt="" />
            :
            <div className="user-icon">
                <FaUserAlt />
            </div>
        }
        <span className="body">
            <span className="username">{(props.name).slice(0, 30)}</span>
            <span className="lastMessage">{props.message}</span>
        </span>
        <span className="moment">{obterHoraMinuto(props.createdAt)}</span>
    </WrapperChatCard>
}